<template>
  <div class="newsDetail">
    <div class="title">{{ $t("news.title2") }}</div>
    <div class="news-detail">
      <div class="news-title">{{ newsData.title }}</div>
      <div class="news-info">
        <div class="news-type">{{ newsData.categoryName }}</div>
        <span class="news-date">{{ newsData.createtime }}</span>
        <div class="source">
          <span>{{ $t("news.source") }}：</span>
          <a :href="newsData.sourceUrl">{{ newsData.sourceName }}</a>
        </div>
      </div>
      <div class="news-text" v-html="newsData.fullText"></div>
    </div>
    <back-top></back-top>
  </div>
</template>

<script>
import { getNewsDetail } from "../../network/news";
import BackTop from "../../components/content/backTop/BackTop.vue";
export default {
  name: "NewsDetail",
  data() {
    return {
      newsData: {},
    };
  },
  created() {
    this.getNewsDetail(this.$route.params.nid);
  },
  methods: {
    getNewsDetail(id) {
      getNewsDetail(id).then((res) => {
        // console.log(res);
        if (res.status !== "ok") {
          return this.$message.error(this.$t("news.tip4"));
        }
        // if (res.data.fullText.indexOf("img") !== -1) {
        //   var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
        //   var s = res.data.fullText.match(b); // 取到所有img标签 放到数组 s里面
        //   for (var i = 0; i < s.length; i++) {
        //     var srcImg = s[i].replace(b, "$1"); //取src面的内容
        //     if (srcImg.slice(0, 4) == "http" || srcImg.slice(0, 5) == "https") {
        //       //若src前4位置或者前5位是http、https则不做任何修改
        //       //console.log("不做任何修改");
        //     } else {
        //       //修改富文本字符串内容 img标签src 相对路径改为绝对路径
        //       res.data.fullText = res.data.fullText.replace(
        //         new RegExp(srcImg, "g"),
        //         "http://mffind.nfds.vip" + srcImg
        //       );
        //     }
        //   }
        // }

        res.data.createtime = res.data.createtime.replace("T", " ");
        this.newsData = res.data;
        // console.log(res.data.fullText);
      });
    },
  },

  components: {
    BackTop,
  },

  computed: {},
};
</script>
<style lang='less' scoped>
@import "../../assets/css/newsDetail.less";
</style>